/* ExplainerModal.module.css */

.modal-header {
  background: white;
  color: black;
  font-size: 22px;
  font-weight: bold;
  padding: 18px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  text-align: center;
  display: flex;
  justify-content: center;  /* Center content */
  align-items: center;
  border-bottom: 2px solid black;
}

.modal-header h4 {
  margin: 0 auto; /* Ensure text stays centered */
  font-size: 24px;
  letter-spacing: 1px;
  flex-grow: 1; /* Allows the text to take up space and center properly */
  text-align: center;
}

.dismiss-button {
  background-color: #137A08;
  color: #F7E27D; /* Softer yellow */
  font-size: 16px; /* Reduced from 18px */
  padding: 8px 16px; /* Reduced from 10px 20px */
  border-radius: 6px; /* Slightly less rounded */
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.dismiss-button:hover {
  background-color: #0e5e06;
  color: #FFF9C4; /* Even lighter yellow on hover */
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 5px rgba(255, 255, 0, 0.6);
  }
  50% {
    transform: scale(1.1);
    box-shadow: 0 0 15px rgba(255, 255, 0, 1);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 5px rgba(255, 255, 0, 0.6);
  }
}

.homepage-explainer {
  animation: pulse 1.5s ease-in-out 3; /* Pulsate 3 times */
  background-color: #036F36 !important; /* Ensure primary color */
  color: #FFF605 !important;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}


