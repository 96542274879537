.required {
    color: red;
  }

/* .sidebar-title-container{
  position: fixed;
    top: 0;
    right: 0;
    width: 450px;
    height: 100vh;
    background-color: white;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
    z-index: 1100;
    transition: transform 0.3s ease;
    transform: translateX(0);
    display: flex;
    flex-direction: column;
    overflow: hidden;
} */

.sidebar-header {
  display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    background-color: white;
    font-size: 18px;
    font-weight: 700;
    color: #5c5c5c;
}

.close-icon:hover {
  color: #d9534f; 
}
