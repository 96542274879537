.custom-modal {
    max-width: 600px;
    margin: auto;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    overflow: hidden;
  }
  
  .modal-body {
    padding: 20px;
  }

  .modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
  }

  .modal-footer .btn-primary {
    background-color: #137A08;
    border-color: #137A08;
    transition: background-color 0.3s ease;
  }

  .modal-footer .btn-secondary {
    background-color: #c4c4c4;
    border-color: #c4c4c4;
    color: black;
  }

  .modal-body label {
    font-weight: bold;
    margin-top: 15px;
    display: block;
  }