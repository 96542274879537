/* Sidebar container */
.notification-sidebar {
  width: 400px;
  background: #fff;
  position: fixed;
  top: 0;
  right: -400px; /* Hidden by default */
  height: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease;
  z-index: 1050;
}

.notification-sidebar.open {
  right: 0; /* Show when open */
}

/* Sidebar header */
.sidebar-header {
  font-size: 1.2rem;
  font-weight: bold;
  background: #f8f9fa;
  border-bottom: 1px solid #ddd;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Notification card container */
.notification-card {
  padding: 15px;
  margin: 10px 0;
  border: 1px solid #1e1d1d;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  transition: background-color 0.3s, box-shadow 0.3s;
  cursor: pointer;
}

/* Hover effect for notifications */
.notification-card:hover {
  background-color: #f9f9f9;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

/* Unread and read states */
.notification-card.unread {
  border-left: 5px solid #036F36;
}

.notification-card.read {
  border-left: 5px solid #28a745;
}

/* Profile image style */
.notification-profile {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

/* Notification info container */
.notification-info {
  flex: 1;
}

/* Notification message link style */
.notification-message {
  color: #036F36  ;
  text-decoration-color: 036F36;
  font-size: 14px;
  font-weight: 500;
  margin-left: 8px;
  text-decoration: underline;
}

.notification-message:hover {
  text-decoration: underline;
}

/* Tag styling */
.tag {
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 4px;
  color: #fff;
  text-transform: capitalize;
  margin-top: 5px;  
}

.tag.benchmarking {
  background-color: #97FCC7;
  color: #000000;
}

.tag.landscaping {
  background-color: #FEDCDC;
  color: #450202;
}

.tag.e_tracker {
  background-color: #FFFDB8;
  color: #036F36;
}

/* Status dot styles */
.status-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left: 10px;
}

.red-dot {
  background-color: #E51010;
}

.green-dot {
  background-color: green;
}

/* Adjust the "last updated" text alignment */
.timestamp {
  margin-left: 60px; /* Adjust this value as needed */
}

/* Toggle switch container adjustments */
.toggle-switch {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Toggle switch text style */
.toggle-text {
  /* margin-left: 8px; */
  font-weight: 600;
  font-size: 0.9rem;
  color: #333;
}

.close-button {
  background: none;
    border: none;
    cursor: pointer;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 2px solid #036f36;
    font-size: 14px;
    font-weight: bold;
    color: #036f36;
    transition: background-color 0.3s ease;
}

/* Toggle switch styles */
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
  margin: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #007bff;
}

input:checked + .slider:before {
  transform: translateX(14px);
}