.stage-explainer-modal-header {
  background: white !important;;
  color: black;
  font-size: 22px;
  font-weight: bold;
  padding: 18px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  text-align: center;
  display: flex;
  justify-content: center;  /* Center content */
  align-items: center;
  border-bottom: 2px solid black;
}

.stage-explainer-modal-header h4 {
  margin: 0 auto; /* Ensure text stays centered */
  font-size: 24px;
  letter-spacing: 1px;
  flex-grow: 1; /* Allows the text to take up space and center properly */
  text-align: center;
}

.stages_explainer{
  list-style-type: none;
  padding-left: 0;
}

.stages_explainer h5{
    color: #4D4D4D;
    margin: 5px 0px;
    padding : 10px 0px;
    border-bottom: 1px solid #4D4D4D;
    font-weight: bold;
}

.stages_explainer ol li::before{
    list-style: decimal !important;
    font-weight: bold;
}

.dismiss-button {
  background-color: #137A08;
  color: #F7E27D; /* Softer yellow */
  font-size: 16px; /* Reduced from 18px */
  padding: 8px 16px; /* Reduced from 10px 20px */
  border-radius: 6px; /* Slightly less rounded */
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.dismiss-button:hover {
  background-color: #0e5e06;
  color: #FFF9C4; /* Even lighter yellow on hover */
}