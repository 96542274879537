.page-content {
    padding: 30px;
    background-color: #fff; /* Set page background to white */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 767.98px) {
    .user-detail-card {
        padding: 15px;
    }
    
    .profile-card {
        width: 100%; /* Full width on smaller screens */
        margin-bottom: 20px;
    }

    .user-info-table {
        padding-left: 0;
    }

    .info-value {
        flex: 1; /* Adjust flex for better spacing */
    }
}

@media (min-width: 768px) {
    .user-detail-card {
        padding: 20px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #fff; /* Form background set to white */
    }
    
    .profile-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #F0F0F0; /* Profile card background color */
        border-radius: 16px;
        padding: 36px;
        gap: 30px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
        min-height: 250px;
        width: 200px;
        height: 100%;
    }
}

.profile-image {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
    border: 2px solid #137a08;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.status-badge {
    display: flex;
    align-items: center;
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #FFFFFF;
    border-radius: 20px;
}

.status-badge .dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
}

.bg-danger {
    background-color: #dc3545;
}

.bg-success {
    background-color: #28a745;
}

.user-info-table {
    padding-left: 30px;
    background-color: #fff;
}

.user-info-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    border-bottom: 1px solid #e0e0e0;
}

.user-info-row:last-child {
    border-bottom: none;
}

.info-icon {
    flex: 0 0 30px;
    font-size: 16px;
    color: #747272;
}

.info-label {
    flex: 1;
    font-weight: 400;
    color: #747272;
}

.info-value {
    flex: 2;
    font-weight: 500;
    color: #1D1D1D;
}

/* Add space to the right of the links */
.text-primary {
    color: #137a08;
    margin-left: 10px; /* Add margin to the links */
}

@media (max-width: 767.98px) {
    .text-primary {
        margin-left: 5px; /* Reduce margin on smaller screens */
    }
}

/* Adjust the layout of the edit section */
.edit-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

/* Style for username input during edit mode */
.username-input {
  margin-right: 10px;
  max-width: 200px;
}

/* Button styling for action buttons */
.action-buttons {
  display: flex;
  gap: 10px;
}

button.action-button {
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: transparent;
}

button.action-button.success {
  color: #137a08;
}

button.action-button.danger {
  color: #747272;
}

/* Container adjustments (if needed) */
.toggle-user-status {
    /* Ensure items are aligned in the center */
    align-items: center;
  }
  
  /* Optional: you can adjust the text container if needed */
  .toggle-text {
    margin-right: 10px; /* adjust spacing as desired */
  }
  
  /* Switch Styles */
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  /* Hide default checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* Slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: green; /* off state color */
    transition: 0.4s;
  }
  
  /* When checkbox is checked, change slider background */
  .switch input:checked + .slider {
    background-color: red;
  }
  
  /* Slider knob */
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
  }
  
  .switch input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  /* Rounded slider */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
